import '@/assets/css/tailwind.css'
import '@/assets/css/main.css'
import '@/assets/style.css'
import 'material-design-icons/iconfont/material-icons.css'
import 'vue-select/dist/vue-select.css';
import 'vue-datetime/dist/vue-datetime.css'
import vSelect from 'vue-select'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import functions from './plugins/functions'
import server from './plugins/server'
import colors from './plugins/colors'
import jwtService from "./plugins/jwt.service";
import moment from 'moment';
import countries from '@/assets/data/countries.json'
import VueInsProgressBar from 'vue-ins-progress-bar'
import Progress from "easy-circular-progress";
import momentTimezone from 'moment-timezone';


import components from './components'
import VueSimpleAlert from "vue-simple-alert";

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

import Tabs from 'vue-tabs-component';
Vue.use(Tabs);

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true // add this line

Vue.use(VueClipboard)


import HighchartsVue from 'highcharts-vue'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.use(HighchartsVue, {tagName: 'highcharts'})

Vue.component('apexchart', VueApexCharts)
Vue.component('date-picker', DatePicker);

import MultiRangeSlider from "multi-range-slider-vue";
import "../node_modules/multi-range-slider-vue/MultiRangeSliderBlack.css";
import "../node_modules/multi-range-slider-vue/MultiRangeSliderBarOnly.css";

Vue.component('MultiRangeSlider', MultiRangeSlider);

// i18n
import i18n from './i18n/i18n' 

import _ from 'lodash';
//loiding plugin
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading', Loading);
Vue.component('Progress', Progress);
import Modal from "@burhanahmeed/vue-modal-2";
Vue.use(Modal);
Vue.use(VueSimpleAlert);


import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_ENV_RECAPTCHA_SITE_KEY})
Vue.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    position: "top-center",
    timeout: 1300,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
  });



const options = {
    position: 'fixed',
    show: true,
    height: '8px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
      },
  }
  
Vue.use(VueInsProgressBar, options)
//Vue.use(Swal);

import { Datetime } from 'vue-datetime'
Vue.component('datetime-v2', Datetime);

import VueBarcode from '@chenfengyuan/vue-barcode';
Vue.component('vue-barcode', VueBarcode);


components.forEach(c => { Vue.component(c.name, c.component) });
Vue.component('v-select', vSelect)

Vue.prototype.$f = functions;
Vue.prototype.$server = server;
Vue.prototype.$colors = colors;
Vue.prototype.$jwtService = jwtService;
Vue.prototype.$me = server.me("users");
Vue.prototype.$moment = moment;
Vue.prototype.$countries = countries;
Vue.prototype.$momentTimezone = momentTimezone;


//jwtService.saveToken()
//jwtService.destroyToken()
//console.log(jwtService.getToken())







Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: function(h) { return h(App) }
}).$mount('#app')