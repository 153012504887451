export default {
    toggle: function(selector, prop, from, to) {
        const el = document.querySelector(selector);
        if (getComputedStyle(el)[prop] == from) el.style[prop] = to;
        else el.style[prop] = from;
    },
    tranStatus: function(value) {
        const status = {
            pending: "En attente",
            processing: "En cours",
            completed: "Terminee",
            canceled: "Annulee"
        }
        return status[value];
    }, 
    getCountryCode: function(countryCode) {
        if(countryCode){
            return "https://static.dwcdn.net/css/flag-icons/flags/4x3/" + countryCode.toLowerCase() + ".svg";
        }
    },
    getCountryNameByCode: function(data,Code) {
        for(var prop in data){
            if(data.hasOwnProperty(prop)){
                if(data[prop]['code'] === Code) {
                    return data[prop]['name'];
                    break;
                }
            }
        }
    },
    getCurrencyByCode: function(data,Code) {
        for(var prop in data){
            if(data.hasOwnProperty(prop)){
                if(data[prop]['code'] === Code) {
                    return data[prop]['currencyCode'];
                    break;
                }
            }
        }
    },
    getcurrencyCodeByCountry: function(data,country) {
        for(var prop in data){
            if(data.hasOwnProperty(prop)){
                if(data[prop]['code'] === country) {
                    return data[prop]['currencyCode'];
                    break;
                }
            }
        }
    },
    getIdbySplit: function(id) {
        let NewId="";
        if(id){
        var lengthId=id.toString().split('-').length;

        if(lengthId==1){
            return id.toString().split('-')[0];
        }else{
            for(let i in id.split('-')){
                if(i != id.split('-').length - 1){
                    NewId+=id.split('-')[i];
                    if(id.split('-').length-2 != i) NewId+='-';
                }
                
            }
            return NewId;
        }
      }
    },
    getElementInArrayByCountry(data,country,qte,manuelCreate=null){
        console.log('details pr----------',data)
        console.log('country----------',country)
        console.log('qte----------',qte)
       
        var result  = data.filter(function(o){
           if(!manuelCreate) return o.warehouse.country === country && o.warehouse.parent && o.warehouse.parent._id && o.quantity.inStock && o.quantity.inStock>=qte && !o.outOfStock;
           else return o.warehouse.country === country && o.warehouse.parent && o.warehouse.parent._id;
        });
      //  console.log('result warehouse--------',result)

        return result? result : null; // or undefined
    },
    getCountryCodeByName: function(data,Name) {
        for(var prop in data){
            if(data.hasOwnProperty(prop)){
                if(data[prop]['name'] === Name) {
                    return data[prop]['code'];
                    break;
                }
            }
        }
    },
    objectToQueryString: function(obj) {
        var str = [];
        var s = document.location.search;
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]).replace(/%2C/g,","));
          }
          window.history.pushState('', '', '?'+str.join("&"));
    },
    
    QueryStringToobject: function() {
        var search = location.search.substring(1);
        if(search) return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
        else return null
    },
    getAccess: function(permissions,modelToCheck, accessToCheck) {
        for (let i in permissions) {
          let model = permissions[i]["model"];
          let access = permissions[i]["access"];
  
          if (model == modelToCheck) {
            return access[accessToCheck];
          }
        }
      },
    getAccessPermission: function(user,modelToCheck,accessToCheck) {
        if (user && user.role && user.role.permissions) {
            const permissions = user.role.permissions;
            for (let i in permissions) {
              let model = permissions[i]["model"];
              let access = permissions[i]["access"];
      
              if (model == modelToCheck) {
                return access[accessToCheck];
              }
            }
        }
      },
      validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      },
      ColorsStatusShippings: (item) => {
        switch (item) { 
            case 'to prepare':
                return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                break;
            case 'outofstock':
                return "color: rgb(203 21 160); border-color: rgb(203 21 160);"
                break;
            case 'prepared':
                return "color:rgb(8, 194, 226); border-color: rgb(8, 194, 226);"
                break;
            case 'shipped':
                return "color:rgb(106 71 9); border-color: rgb(106 71 9);"
                break;
            case 'delivered':
                return "color:rgb(58 217 18); border-color: rgb(58 217 18);"
                break;
            case 'paid':
                return "color:rgb(194, 168, 27); border-color: rgb(194, 168, 27);"
                break;
            case 'processed':
                return "color:rgb(215, 26, 230); border-color: rgb(215, 26, 230);"
                break;            
            case 'cancelled':
                return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                break;
            case 'refused':
                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                    break;
            case 'remind':
                return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                break;    
            case 'unreachable':
                return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                break;
                case 'pending return':
                    return "color: rgb(227 129 210); border-color: rgb(227 129 210);"
                    break;    
                case 'return':
                    return "color: rgb(141 8 118); border-color: rgb(141 8 118);"
                    break;    
        }
    },
    removeCountryByTwoArray(array1, array2) {
        //  return array1.filter(item => array2.map(el => el.code||el).includes(item.country));
        return array1;
      },
      defaultCategories(){
          return ["Électronique", 'Vêtements', 'Maison et jardin', 'Alimentation et boissons', 'Santé et beauté', 'Sports et loisirs', 'Automobile et moto', 'Bébé et enfant', 'Animaux de compagnie', 'Électroménager', 'Outils et bricolage', 'Bijoux et montres', 'Cosmétique'];
      },
      splitArrayIntoChunks(array, chunkSize) {
          let result = [];
          for (let i = 0; i < array.length; i += chunkSize) {
              result.push(array.slice(i, i + chunkSize));
          }
          return result;
      },
      removeObjectKey(obj, key) {
          if (obj.hasOwnProperty(key)) {
              delete obj[key];
          }
          return obj;
      }
}